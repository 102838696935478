@import url('https://fonts.googleapis.com/css2?family=Phetsarath:wght@400;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body {
  padding: 0;
  margin: 0;
  overflow-x: hidden;  
}
  
* {
  box-sizing: border-box;
}
 
/* src/index.css or src/styles.css */

/* Define custom font with @font-face */

@font-face {
  font-family: 'Phetsarath';
  src: url('https://gauravengg.com/fonts/PhetsarathBold.woff2') format('woff2');
  font-display: swap;
}

/* Optional: Provide a fallback font */
body {
  font-family: 'Phetsarath';
}

